.animate-button {
  background: linear-gradient(to right, #6366f1 0%, #d946ef 50%, #6366f1 100%);
  background-size: 200% auto;
  animation: pulse-gradient 3.5s ease-in-out infinite;
}

@keyframes pulse-gradient {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.animate-waiter-dark {
  background: linear-gradient(to right, #373947 0%, #4d4f5b 50%, #373947 100%);
  background-size: 200% auto;
  animation: pulse-gradient 3.5s ease-in infinite;
}

@keyframes pulse-gradient {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.animate-waiter-light {
  background: linear-gradient(to right, #e2e8f0 0%, #f1f5f9 50%, #e2e8f0 100%);
  background-size: 200% auto;
  animation: pulse-gradient 3.5s ease-in infinite;
}

@keyframes pulse-gradient {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
